import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import contact from "../assets/images/contact.jpg";

import { ReactComponent as MobileIcon } from "../assets/icons/mobile.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/linkedin.svg";

class Contact extends React.Component {
  render() {
    return (
      <Container id="contact">
        <Content>
          <Left>
            <LeftContent>
              <BigTitle>
                <FormattedMessage id="contact_title" />
              </BigTitle>

              <MediumText>
                <FormattedMessage id="contact_text_1" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="contact_text_2" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="contact_text_3" />
              </MediumText>

              <Info>
                <InfoItem>
                  <FormattedMessage id="contact_morgan" />

                  <br />

                  <Link href="tel:+352691349347">
                    <SVGMobile>
                      <MobileIcon />
                    </SVGMobile>
                    (+352) 691 349 347
                  </Link>
                </InfoItem>

                <InfoItem>
                  <FormattedMessage id="contact_pierre" />

                  <br />

                  <Link href="tel:+352691349348">
                    <SVGMobile>
                      <MobileIcon />
                    </SVGMobile>
                    (+352) 691 349 348
                  </Link>
                </InfoItem>

                <InfoItem>
                  <FormattedMessage id="contact_mp" />

                  <br />

                  <Link href=" https://www.linkedin.com/company/mp-consulting-lu/about/">
                    <SVGLinkedin>
                      <LinkedinIcon />
                    </SVGLinkedin>
                    Linked in
                  </Link>
                </InfoItem>
              </Info>

              <BigTitle>
                <FormattedMessage id="contact_career_title" />
              </BigTitle>

              <MediumText>
                <FormattedMessage id="contact_career_text_1" />
              </MediumText>

              <MediumText>
                <FormattedMessage
                  id="contact_career_text_2"
                  values={{
                    ul: (msg) => <ul>{msg}</ul>,
                    li: (msg) => <li>{msg}</li>,
                  }}
                />
              </MediumText>

              <MediumText>
                <FormattedMessage id="contact_career_text_3" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="contact_career_text_4" />
                <Link href="mailto:contact@mp-consulting.lu">
                  contact@mp-consulting.lu
                </Link>
              </MediumText>
            </LeftContent>
          </Left>

          <Right></Right>
        </Content>
      </Container>
    );
  }
}

export default Contact;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tertiary};
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  flex: 1;
  max-width: 1920px;
  display: flex;
`;

const BigTitle = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: OpenSansBold;
  color: ${(props) => props.theme.fontLight};

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const SVGMobile = styled.div`
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 3px;
  height: 10px;
  width: 10px;
  color: ${(props) => props.theme.primary};
  cursor: pointer;
`;

const SVGLinkedin = styled.div`
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
  color: ${(props) => props.theme.primary};
  cursor: pointer;
`;

const MediumText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.fontLight};

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;

  @media (max-width: 600px) {
    margin: 2rem 0;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
`;

const InfoItem = styled.div`
  color: ${(props) => props.theme.fontLight};

  @media (max-width: 600px) {
    margin: 0.5rem 0;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
  text-decoration: none;

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const Left = styled.div`
  padding: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LeftContent = styled.div`
  text-align: justify;
  text-justify: inter-word;
  width: 80%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Right = styled.div`
  flex: 1;
  background-image: url(${contact});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1240px) {
    display: none;
    visibility: hidden;
  }
`;
