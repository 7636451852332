import React, { useState, Fragment } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";
import { SECTIONS } from "../constants/sections";

import Menu from "../assets/icons/hamburger-menu.svg";

/*the offset is the size of the parent container*/
const NavMobile = ({ offset }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    console.log("toggle modal");
    setShowModal(!showModal);
  };

  const modal = () => (
    <Modal onClick={toggleModal}>
      <ModalContent onClick={(event) => event.stopPropagation()}>
        {linksLoop()}
      </ModalContent>
    </Modal>
  );

  const linksLoop = () => {
    return SECTIONS.map((section) => {
      let sectionOffset = !section.offset ? 0 : offset;

      return (
        <StyledLink
          key={section.key}
          to={section.key.toLowerCase()}
          smooth={true}
          spy={true}
          offset={-sectionOffset}
          duration={700}
          onClick={toggleModal}
        >
          <p>{section.label}</p>
        </StyledLink>
      );
    });
  };

  return (
    <Fragment>
      <Container>
        <Content onClick={toggleModal}>
          <Icon src={Menu} />
        </Content>
      </Container>

      {showModal && modal()}
    </Fragment>
  );
};

export default NavMobile;

const Container = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  height: 35px;
  width: 35px;
`;

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  width: 20rem;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: bold;

  border-bottom: 1px solid ${(props) => props.theme.fontDark};

  &:last-child {
    border: none;
  }

  p {
    margin: 0 0 5px 0;
  }
`;
