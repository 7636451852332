import React from 'react'
import styled from 'styled-components'

import logo from '../assets/logos/logo.png'

class Footer extends React.Component {

  render() {
    return (
      <Container>
        <Paragraph>
          <Text>MP consulting SARL-S</Text>
          <SmallText>contact@mp-consulting.lu</SmallText>
        </Paragraph>
        <Paragraph>
          <Image src={logo}/>
        </Paragraph>
      </Container> 
    )
  }
}

export default Footer

const Container = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  display: table;
  background-color: ${props => props.theme.default};
`

const Paragraph = styled.div`
  padding: 2rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: ${props => props.theme.default};
`

const Text = styled.p`
  text-align: left;
  font-size: 2rem;
  margin: 0;
  color: ${props => props.theme.fontTertiary};

  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`

const SmallText = styled.p`
 text-align: left;
  font-size: 1.4rem;
  margin: 0;
  color: ${props => props.theme.fontTertiary};

  @media (max-width: 800px) {
    font-size: 0.8rem;
  }
`

const Image = styled.img`
  height: 100px;
  float: right;

  @media (max-width: 800px) {
    height: 50px;
  }
`