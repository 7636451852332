import React from "react";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";

import morganImg from "../assets/images/morgan-joseph.jpg";
import pierreImg from "../assets/images/pierre-lichtfus.jpg";
import julienImg from "../assets/images/julien-koepp.jpg";

import Carousel, {
  Dots,
  slidesToShowPlugin,
  arrowsPlugin,
  autoplayPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import Card from "../component/card";

const Experts = () => {
  //TODO refacto to generate all personne from a json file
  const morgan = () => (
    <Card
      urlPath={morganImg}
      name="Morgan JOSEPH"
      title="Associé"
      skills="Full stack developer"
    />
  );

  const pierre = () => (
    <Card
      urlPath={pierreImg}
      name="Pierre LICHTFUS"
      title="Associé"
      skills="Full stack developer"
    />
  );

  const julien = () => (
    <Card
      urlPath={julienImg}
      name="Julien Koepp"
      title="Resp. des technologies"
      skills="Full stack developer"
    />
  );

  const arrowLeft = () => (
    <ArrowContainer>
      <Arrow left />
    </ArrowContainer>
  );

  const arrowRight = () => (
    <ArrowContainer>
      <Arrow right />
    </ArrowContainer>
  );

  const carouselConfig = () => {
    return [
      "centered",
      "infinite",
      "fastSwipe",
      {
        resolve: arrowsPlugin,
        options: {
          arrowLeft: arrowLeft(),
          arrowRight: arrowRight(),
          addArrowClickHandler: true,
        },
      },
    ];
  };

  const carouselResponsiveConvifg = () => {
    return {
      560: {
        plugins: [
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 1,
            },
          },
        ],
      },
      820: {
        plugins: [
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 1,
            },
          },
          ...carouselConfig(),
        ],
      },
      1100: {
        plugins: [
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 2,
            },
          },
          ...carouselConfig(),
        ],
      },
    };
  };

  return (
    <Container id="experts">
      <BigTitle>
        <FormattedMessage id="experts_title" />
      </BigTitle>

      <Content>
        <StyledCarousel
          plugins={[
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3,
              },
            },
            ...carouselConfig(),
          ]}
          breakpoints={carouselResponsiveConvifg()}
        >
          {morgan()}
          {pierre()}
          {julien()}
        </StyledCarousel>
      </Content>
    </Container>
  );
};

export default Experts;

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  background-color: ${(props) => props.theme.default};
`;

const Content = styled.div`
  margin: 0 auto;
  width: 1240px;
  @media (max-width: 1300px) {
    margin: 0 0;
    width: 100%;
  }
`;

const StyledCarousel = styled(Carousel)`
  .BrainhubCarousel__trackContainer {
    @media (min-width: 560px) {
      overflow: hidden;
      position: relative;

      &:before,
      &:after {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        content: "";
        height: 100%;
        position: absolute;
        width: 50px;
        z-index: 2;
      }

      &:after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
      }

      &:before {
        left: 0;
        top: 0;
      }
    }
  }
`;

const BigTitle = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: OpenSansBold;
  color: ${(props) => props.theme.fontDark};

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const FillElement = styled.div`
  flex: 1;
`;

const Element = styled.div`
  flex-grow: 0;
`;

const ArrowContainer = styled.div`
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Arrow = styled.div`
  position: absolute;
  padding: 2rem;
  box-shadow: 1px -1px 0 1px #757575 inset;
  -webkit-box-shadow: 2px -2px #757575 inset;
  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  transition: 0.2s;

  &:hover {
    box-shadow: 2px -2px 0 2px #757575 inset;
    -webkit-box-shadow: 4px -4px #757575 inset;
  }

  ${(props) =>
    props.left &&
    css`
      transform: rotate(45deg);
    `}

  ${(props) =>
    props.right &&
    css`
      transform: rotate(225deg);
    `}
`;
