export const en = {
  home_title: "Your IT experts {linebreak} to help you reach your goals!",
  // expertis
  expertis_title: "Our expertise",
  expertis_text_1:
    "We help you reach your IT goals by reinforcing your teams and taking part in every step of your projects.",
  expertis_text_2:
    "Our flexible work environment enables us to respond quickly and adapt to your requests. With a flat structure we are able to communicate efficiently and make decisions with all components at hand.",
  expertis_software_development_title: "Software development",
  expertis_software_development_text:
    "Mobile/desktop application, web platforms, software : MP Consulting’s experts specialise in all aspects of development: user requirements, guildelines, business process, redesign, testing and maintenance.",
  expertis_business_intelligence_title: "Business Intelligence",
  expertis_business_intelligence_text:
    "Our consultants cover all aspects of Business Intelligence: Data Warehouse architecture and Design, ETL, Databases, Datamarts implementation and reporting. Your company will be able to make strategic decisions based on reliable data and analysis.",
  expertis_infrastructure_network_title: "Infrastructure and network",
  expertis_infrastructure_network_text:
    "Our experts are trained to design, consolidate and maintain secure and reliable networks. Your teams can rely on us for well-designed server and application architecture in order to operate without interruptions.",
  expertis_it_operations_title: "IT operations",
  expertis_it_operations_text:
    "MP Consulting can set up and improve new or existing processes, frameworks and procedures to optimize all the operations enabling you to lead business successfully.",
  // services
  services_title: "Our services",
  services_test_1:
    "We find the perfect fit to help your teams surpass their goals based on our consultants’ skills, expertise, and experience to match your company.",
  services_test_2:
    "Our experts are trained to identify strengths and areas of improvements in order to find the best approach to reach your goals. They can come in as experts, consultants, fully integrate as part of your teams or can even build custom-made projects on their own. It all depends on your goals.",
  services_test_3:
    "We adapt to your company’s methodologies, technologies and environment all the while offering our expertise to help you develop top-notch projects, delivered on time and on scope.",
  services_test_4:
    "Our consultants integrate your teams in-house or remotely and bring their knowledge to your projects. They ensure quality and bring their passion for innovation to develop long lasting solutions that anticipate market trends and beat complex requirements.",
  services_test_5:
    "Our goal is to build durable solutions and relationships with and for our partners.",
  // references
  references_title: "Our references",
  references_experiences_in: "Our teams have a strong experience in ",
  referneces_experiences_text:
    "Working in finance, insurance, national research, security & trust as well as the automotive industry, our teams have had the chance to develop different solutions to help simplify users lives : a CRM (Customer relationship management) for insurance agents, an electronic signature platform for the private and public sector as well as the goverment ; multiple mobile and desktop applications and web plaforms for big players based throughout Luxembourg, Belgium, France.",
  references_methodologies:
    "Our teams work with different methologies such as Scrum, Agile, Kanban.",
  // experts
  experts_title: "Our team",
  // contact
  contact_title: "What’s next?",
  contact_text_1:
    "Give us a call or email and we’ll set up an appointment to meet you and discuss your needs, your goals and projects and figure out where we can help.",
  contact_text_2:
    "This moment is crucial in order for us to fully understand the scope of your projects, their specificities, technologies and skills needed.",
  contact_text_3:
    "Also this gives us the opportunity to get in touch with your work environment and understand what makes your team unique in order to find the best match for your company.",
  contact_morgan: "Morgan JOSEPH",
  contact_pierre: "Pierre LICHTFUS",
  contact_mp: "MP Consulting",
  contact_career_title: "We are hiring",
  contact_career_text_1: "Are you the one we're looking for?",
  contact_career_text_2:
    "<ul> <li>You’re passionate about programming</li> <li>You love to solve problems</li> <li>You like learning new technologies</li> <li>You enjoy sharing your knowledge</li> <li>Your code is clean and well tested</li> <li>You are experienced in building web applications</li></ul>",
  contact_career_text_3: "Come join our team of experts!",
  contact_career_text_4: "Apply at : ",
  contact_career_email: "contact@mpconsulting.lu",
};
