import React, { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { SECTIONS } from "../constants/sections";
import debounce from "lodash.debounce";

import Nav from "./nav";
import NavMobile from "./nav-mobile";

import logo from "../assets/logos/logo-2.png";

const Header = ({ className }) => {
  const container = useRef(null);

  const [containerMetrics, setContainerMetrics] = useState({
    width: 0,
    height: 0,
  });

  window.addEventListener(
    "resize",
    debounce(() => {
      calculeContainerMetrics();
    }, 300)
  );

  useLayoutEffect(() => {
    calculeContainerMetrics();
  }, []);

  const calculeContainerMetrics = () => {
    if (container.current) {
      const width = container.current.clientWidth;
      const height = container.current.clientHeight;

      setContainerMetrics({ width, height });
    }
  };

  const isNavModal = () => {
    return containerMetrics.width <= 640;
  };

  return (
    <Container id="header" ref={container} className={className}>
      <SpaceFix />

      <LogoContainer to={"home"} smooth={true}>
        <Logo src={logo} />
      </LogoContainer>

      <SpaceGrow />

      {!isNavModal() && <Nav offset={containerMetrics.height} />}
      {isNavModal() && <NavMobile offset={containerMetrics.height} />}

      <SpaceFix />
    </Container>
  );
};

Header.propTypes = {};

export default Header;

const Container = styled.div`
  z-index: 999;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${(props) => props.theme.fontSecondary};
`;

const LogoContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 80px;
  width: 80px;

  @media (max-width: 768px) {
    width: 60px;
    width: 60px;
  }
`;

const SpaceGrow = styled.div`
  flex-grow: 1;
`;

const SpaceFix = styled.div`
  width: 8rem;

  @media (max-width: 1024px) {
    width: 2rem;
  }
`;
