import React from "react";
import { FormattedMessage } from "react-intl";
import logo from "../assets/logos/logo.png";
import backgound from "../assets/images/home-background.jpg";
import styled, { keyframes } from "styled-components";
import { Link } from "react-scroll";

const NEXT_SECTION = { key: "expertise", label: "Expertise", offset: true };

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      isChevronVisible: true,
    };
  }

  hideChevron = () => {
    this.setState({ isChevronVisible: false });
  };

  chevronClass = () => {
    return this.state.isChevronVisible ? "show-chevron" : "hide-chevron";
  };

  componentDidMount() {
    const height = document.getElementById("header").clientHeight;
    this.setState({ height });
  }

  render() {
    return (
      <Container id="home">
        <Center>
          <Image src={logo} />
          <SubTitle>
            <FormattedMessage id="home_title" values={{ linebreak: <br /> }} />
          </SubTitle>
        </Center>

        <ChevronContainer>
          <Chevron
            onClick={this.hideChevron}
            className={this.chevronClass}
            activeClass={"show-chevron"}
            to={NEXT_SECTION.key.toLowerCase()}
            smooth={true}
            spy={true}
            offset={-this.state.height}
            duration={700}
          ></Chevron>
        </ChevronContainer>
      </Container>
    );
  }
}

export default Home;

const FadeOut = keyframes`
	from { opacity: 0.8; }
    to   { opacity: 0; }
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  background-image: url(${backgound});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .show-chevron {
    opacity: 0;
    animation: ${FadeOut} 0.3s;
  }

  .hide-chevron {
    opacity: 0;
  }
`;

const Center = styled.div`
  flex: 1;
  align-self: center;
  text-align: center;
`;

const Image = styled.img`
  max-width: 1000px;
  width: 200px;
  height: auto;

  @media (max-width: 800px) {
    max-width: 500px;
  }
`;

const SubTitle = styled.p`
  font-family: MpFont;
  font-size: 3rem;
  font-weight: 100;
  color: ${(props) => props.theme.primary};

  @media (max-width: 800px) {
    font-size: 1.4rem;
  }
`;

const ChevronContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 66px;
`;

const Bounce = keyframes`
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(0, 50px, 0);
	}
`;

const Chevron = styled(Link)`
  display: block;
  position: relative;
  left: -50%;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.secondary};
  //border: 1px solid ${(props) => props.theme.fontDark};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: 0.8;
  animation: ${Bounce} 0.8s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;

  &:before {
    left: 18px;
    border-color: ${(props) => props.theme.fontDark};
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: inline-block;
    height: 13px;
    position: relative;
    top: 15px;
    transform: rotate(135deg);
    vertical-align: top;
    width: 13px;
  }
`;
