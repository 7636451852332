import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import react from "../assets/logos/react.png";
import angular from "../assets/logos/angular.png";
import java from "../assets/logos/java.png";
import kubernetes from "../assets/logos/kubernetes.png";
import nodeJs from "../assets/logos/node-js.png";
import spring from "../assets/logos/spring.png";
import python from "../assets/logos/python.png";
import c from "../assets/logos/c.png";

const LOGOS = [react, angular, java, spring, nodeJs, kubernetes, python, c];

class References extends React.Component {
  logoLoop = () => {
    return LOGOS.map((logo) => <Logo key={logo} src={logo} />);
  };

  render() {
    return (
      <Container id="references">
        <Content>
          <BigTitle>
            <FormattedMessage id="references_title" />
          </BigTitle>

          <SubTitle>
            <FormattedMessage id="references_experiences_in" />
          </SubTitle>

          <LogoContainer>{this.logoLoop()}</LogoContainer>

          <Separtor />

          <SubTitle>
            <FormattedMessage id="references_methodologies" />
          </SubTitle>

          <Separtor />

          <MediumText>
            <FormattedMessage id="referneces_experiences_text" />
          </MediumText>
        </Content>
      </Container>
    );
  }
}

export default References;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.default};
  display: flex;
`;

const Content = styled.div`
  text-align: justify;
  text-justify: inter-word;
  padding: 2rem;
  margin: 0 auto;
  width: 1240px;

  @media (max-width: 1300px) {
    margin: 0 0;
    width: 100%;
  }
`;

const BigTitle = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: OpenSansBold;
  color: ${(props) => props.theme.fontDark};

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.p`
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 24px;
  font-family: "Open Sans", sans-serif;
  color: ${(props) => props.theme.fontDark};
`;

const MediumText = styled.div`
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.fontDark};

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Separtor = styled.div`
  padding-top: 2rem;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Logo = styled.img`
  height: 50px;
  padding: 1rem 2rem;
  width: auto;

  @media (max-width: 600px) {
    height 30px;
     padding: 0.5rem 1rem;
  }

`;
