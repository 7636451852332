import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";
import { SECTIONS } from "../constants/sections";

/*the offset is the size of the parent container*/
const Nav = ({ offset }) => {
  const linksLoop = () => {
    return SECTIONS.map((section) => {
      let sectionOffset = !section.offset ? 0 : offset;

      return (
        <StyledLink
          key={section.key}
          activeClass={"active-nav-link"}
          to={section.key.toLowerCase()}
          smooth={true}
          spy={true}
          offset={-sectionOffset}
          duration={700}
        >
          <p>{section.label}</p>
        </StyledLink>
      );
    });
  };

  return <Container>{linksLoop()}</Container>;
};

export default Nav;

const Container = styled.div`
  display: flex;

  .active-nav-link {
    border-bottom: 2px solid ${(props) => props.theme.primary};
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2px); // sub 2px for the red border
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;

  p {
    margin: 0 0 5px 0;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    padding: 0 0.5rem;
  }
`;
