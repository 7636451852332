import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class Card extends React.Component {
  render() {
    const { urlPath, name, title, skills, className } = this.props;

    return (
      <Container className={className}>
        <Image src={urlPath}></Image>
        <Content>
          <Name>{name}</Name>
          <Text>
            {title}
            <br />
            {skills}
          </Text>
        </Content>
      </Container>
    );
  }
}

export default Card;

Card.propTypes = {
  urlPath: PropTypes.string,
  name: PropTypes.string,
  skills: PropTypes.string,
  className: PropTypes.string,
};

const Container = styled.figure`
  padding: 1.5rem;
  margin: 2rem 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 16px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  box-sizing: border-box;
  width: 15rem;
`;

const Image = styled.div`
  width: 100%;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 15rem;
`;

const Content = styled.figcaption`
  padding-top: 0.5rem;
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: ${(props) => props.theme.fontDark};

  @media (max-width: 800px) {
    font-size: 1rem;
  }
`;

const Name = styled.p`
  margin: 0.5rem 0 0.5rem 0;
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
  color: ${(props) => props.theme.fontDark};
  display: block;

  @media (max-width: 800px) {
    font-size: 1.4em;
  }
`;
