import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import services from "../assets/images/services.jpg";

class Services extends React.Component {
  render() {
    return (
      <Container id="services">
        <Content>
          <Left>
            <LeftContent>
              <BigTitle>
                <FormattedMessage id="services_title" />
              </BigTitle>

              <MediumText>
                <FormattedMessage id="services_test_1" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="services_test_2" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="services_test_3" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="services_test_4" />
              </MediumText>

              <MediumText>
                <FormattedMessage id="services_test_5" />
              </MediumText>
            </LeftContent>
          </Left>

          <Right></Right>
        </Content>
      </Container>
    );
  }
}

export default Services;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tertiary};
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  flex: 1;
  max-width: 1920px;
  display: flex;
`;

const BigTitle = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: OpenSansBold;
  color: ${(props) => props.theme.fontLight};

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const MediumText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.fontLight};

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Left = styled.div`
  padding: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LeftContent = styled.div`
  text-align: justify;
  text-justify: inter-word;

  width: 80%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Right = styled.div`
  flex: 1;
  background-image: url(${services});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1240px) {
    display: none;
    visibility: hidden;
  }
`;
