import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import SoftwareDevelopment from "../assets/icons/software-development.svg";
import BusinessIntelligence from "../assets/icons/business-intelligence.svg";
import InfrastructureNetwork from "../assets/icons/infrastructure-and-network.svg";
import ITOperations from "../assets/icons/it-operations.svg";

class Expertise extends React.Component {
  render() {
    return (
      <Container id="expertise">
        <Content>
          <BigTitle>
            <FormattedMessage id="expertis_title" />
          </BigTitle>

          <MediumText>
            <FormattedMessage id="expertis_text_1" />
          </MediumText>
          <MediumText>
            <FormattedMessage id="expertis_text_2" />
          </MediumText>

          <ExtraMargin />

          <Row>
            <Left>
              {/* Software development */}
              <ColumnParagraph>
                <Icon src={SoftwareDevelopment} />

                <SubTitle>
                  <FormattedMessage id="expertis_software_development_title" />
                </SubTitle>

                <SmallText>
                  <FormattedMessage id="expertis_software_development_text" />
                </SmallText>
              </ColumnParagraph>

              {/* Business intelligence */}
              <ColumnParagraph>
                <Icon src={BusinessIntelligence} />

                <SubTitle>
                  <FormattedMessage id="expertis_business_intelligence_title" />
                </SubTitle>

                <SmallText>
                  <FormattedMessage id="expertis_business_intelligence_text" />
                </SmallText>
              </ColumnParagraph>
            </Left>
            <Right>
              {/* Infrastructure and network */}
              <ColumnParagraph>
                <Icon src={InfrastructureNetwork} />

                <SubTitle>
                  <FormattedMessage id="expertis_infrastructure_network_title" />
                </SubTitle>

                <SmallText>
                  <FormattedMessage id="expertis_infrastructure_network_text" />
                </SmallText>
              </ColumnParagraph>

              {/* it operations */}
              <ColumnParagraph>
                <Icon src={ITOperations} />

                <SubTitle>
                  <FormattedMessage id="expertis_it_operations_title" />
                </SubTitle>

                <SmallText>
                  <FormattedMessage id="expertis_it_operations_text" />
                </SmallText>
              </ColumnParagraph>
            </Right>
          </Row>
        </Content>
      </Container>
    );
  }
}

export default Expertise;

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  background-color: ${(props) => props.theme.default};
`;

const Content = styled.div`
  margin: 0 auto;
  width: 1240px;

  @media (max-width: 1300px) {
    margin: 0 0;
    width: 100%;
  }
`;

const BigTitle = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: OpenSansBold;
  color: ${(props) => props.theme.fontDark};

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const ExtraMargin = styled.div`
  margin-top: 32px;
`;

const Icon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    margin-bottom: 48px;
  }

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }
`;

const SubTitle = styled.p`
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 24px;
  font-family: "Open Sans", sans-serif;
  color: ${(props) => props.theme.fontDark};
`;

const Row = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ColumnParagraph = styled.div`
  text-align: justify;
  text-justify: inter-word;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 32px;

  @media (max-width: 600px) {
    padding: 0px;
  }
`;

const MediumText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 16px;
  padding: 0 32px;
  color: ${(props) => props.theme.fontSecondary};

  @media (max-width: 600px) {
    font-size: 1rem;
    padding 0;
  }
`;

const SmallText = styled.p`
  font-size: 1rem;
  margin: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.fontSecondary};

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;
