export const light = {
  default: '#fff',
  fontDefault: '#fff',

  defaultDark: '#000',

  primary: '#E10F14',
  primaryDark: '#9b2526',
  secondary: '#fff',
  tertiary: '#454545',
 
  fontLight: '#fff',
  fontDark: '#757575',
  
  fontPrimary: '#212121',
  fontSecondary: '#757575',
  fontTertiary: '#bdbdbd',
  
  fontPlaceHolder: '#a4a4a4',

  warrning: '#af4448',
  warrningFont: '#fff'
}