import React, { Fragment } from "react";
import ReactDOM from "react-dom";

// Google Analytics
// import ReactGA from "react-ga";

//Theming
import styled from "styled-components";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { light } from "./config/theme";

//Fonts
import MpFontEot from "./assets/fonts/Return-of-the-Grid.eot";
import MpFontTtf from "./assets/fonts/Return-of-the-Grid.ttf";

import RobotoLightEot from "./assets/fonts/Roboto-Light.eot";
import RobotoLightTtf from "./assets/fonts/Roboto-Light.ttf";

import OpenSansBoldEot from "./assets/fonts/OpenSans-Bold.eot";
import OpenSansBoldTtf from "./assets/fonts/OpenSans-Bold.ttf";

//Components
import Header from "./component/header";

import { IntlProvider } from "react-intl";
import { en } from "./intl/en";

//Pages
import Home from "./page/home";
import Expertise from "./page/expertise";
import Services from "./page/services";
import References from "./page/references";
import Experts from "./page/experts";
import Contact from "./page/contact";
import Footer from "./page/footer";

class App extends React.Component {
  render() {
    //    ReactGA.initialize("UA-130504921-1");

    return (
      <Fragment>
        <GlobalStyle />

        <ThemeProvider theme={light}>
          <IntlProvider messages={en} locale="en" defaultLocale="en">
            <Container>
              <HeaderStyled />

              <Home />
              <Expertise />
              <Services />
              <References />
              <Experts />
              <Contact />

              <Footer />
            </Container>
          </IntlProvider>
        </ThemeProvider>
      </Fragment>
    );
  }
}

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'MpFont';
    src: url(${MpFontEot}); 
    src: url(${MpFontTtf}) format('truetype');
  }

  @font-face {
    font-family: 'RobotoLight';
    src: url(${RobotoLightEot});
    src: url(${RobotoLightTtf}) format('truetype');
  }

  @font-face {
    font-family: 'OpenSansBold';
    src: url(${OpenSansBoldEot});
    src: url(${OpenSansBoldTtf}) format('truetype');
  }

  * {
    font-family: 'RobotoLight';
    outline:none;
  }
  body{
    margin: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.default};
`;

const HeaderStyled = styled(Header)`
  position: fixed;
  width: 100%;
  height: 4.5rem;

  @media (max-width: 768px) {
    height: 3.8rem;
  }
`;

ReactDOM.render(<App />, document.getElementById("root"));
